// Announcements can be used to inform all the parents that sth is about to
// happen, allow them to act and prevent announced action. If no-one prevents
// announcement propagation, the action performs.
const eventName = 'announced'

window.addEventListener(eventName, (announcement) => {
  if (!announcement.performed){
    announcement.performed = true
    announcement.detail._perform(announcement.detail)
  }
})

export default function announce (element, action, details = {})  {
  const event = new CustomEvent(eventName, {
    detail: Object.assign({}, details, { _perform: action.bind(element) }),
    bubbles: true,
    cancelable: true,
    performed: false
  })
  element.dispatchEvent(event)
}
